@import "../../../data/styles.css";

.experience {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
}

.experience a {
	text-decoration: none;
}

.experience:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.experience:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.experience-container {
	padding: 30px;
}

.experience-header {
	display: flex;
	font-weight: 700;
	justify-content: space-between;
	margin-bottom: 1em;
}

/* .experience-logo {
	width: 30px;
}

.experience-logo img {
	width: 100%;
} */

.experience-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-weight: 700;
}

.experience-description {
	color: var(--secondary-color);
}

.experience-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.experience-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.experience-link-text {
	padding-left: 20px;
	font-weight: 700;
}

.experience-date {
	color: var(--secondary-color);
	font-size: 12px;
}

.experience-location {
	color: var(--secondary-color);
	font-size: 12px;
}
