@import "../../data/styles.css";

.education-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.education-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.education-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
}

.education-page-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

@media (max-width: 600px) {
	.education-container {
		padding-top: 60px;
	}

	.education-project {
		width: calc(100% / 2);
	}
}

.all-education-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: -35px;
	margin-right: -35px;
}

.all-education-education {
	width: calc(100%);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}

.all-education-education.homepage {
	width: calc(100% / 2);
}

.education {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
}

.education a {
	text-decoration: none;
}

.education:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.education:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.education-container {
	padding: 30px;
}

.education-header {
	display: flex;
	font-weight: 700;
	justify-content: space-between;
	margin-bottom: 0.25em;
}

/* .education-logo {
	width: 30px;
}

.education-logo img {
	width: 100%;
} */

.education-degree {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-weight: 700;
}

.education-description {
	color: var(--secondary-color);
}

.education-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.education-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.education-link-text {
	padding-left: 20px;
	font-weight: 700;
}

.education-date {
	color: var(--secondary-color);
	font-size: 12px;
}

.education-location {
	color: var(--secondary-color);
	font-size: 12px;
}

@media (max-width: 400px) {
	.all-education-education.homepage {
		width: calc(100%);
	}
}
